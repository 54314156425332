import {GetStaticProps} from 'next';
import {getDataHooksProps} from 'next-data-hooks';

import ErrorPage from '../views/error-page/error-page';
import MyApp from './_app';

export default ErrorPage;

export const getStaticProps: GetStaticProps = async (context) => {
    const dataHooksProps = await getDataHooksProps({
        context,
        dataHooks: [
            ...ErrorPage.dataHooks,
            ...MyApp.dataHooks
        ]
    });
    return {
        props: { ...dataHooksProps }
    };
};
